import React, { useEffect, useState } from 'react';

// redux
import { useSelector, useDispatch } from 'react-redux';
import { USER_PROJECT_NUM } from 'redux/types/userProjectNum.types';

// styled components
import { StyledSuccessProject } from './StyledSuccessProject';

//react icon
import { AiOutlineClose, AiFillEye, AiFillCheckCircle } from 'react-icons/ai';
import { MdOutlineContentCopy } from 'react-icons/md';

// api
import { visitGet } from 'api/visit/visit.api';
import { loginInfoNum } from 'api/loginInfoNum/loginInfoNum.api';

// plugin
import Cookies from 'js-cookie';
import * as dayjs from 'dayjs';

//images
import file from './images/file.svg';
import could from './images/could.svg';

//i18n
import { useTranslation } from 'react-i18next';

const SuccessProject = (props) => {

    const store = useSelector(store => store);
    const dispatch = useDispatch();

    const [thisMonthCount, setThisMonthCount] = useState(0);

    const { t } = useTranslation();

    useEffect(() => {
        visitGet(Cookies.get('token')).then((res) => {
            setThisMonthCount(res.thisMonthCount);
        });
    }, []);

    const [publishUrl, setUrl] = useState('');
    const [projectCount, setProjectCount] = useState(null);
    const [projectPublicCount, setProjectPublicCount] = useState(null);

    useEffect(() => {
        let uid = store.projectInfo.projectInfo.cardListDetail.content.filter(project => project.id === parseInt(Cookies.get('projectId')))[0]?.uuid;
        if (window.location.hostname.indexOf('localhost') > -1) {
            setUrl(`http://${window.location.hostname}:3021/?uid=${encodeURIComponent(uid)}`);
        }

        if (window.location.hostname.indexOf('-dev') > -1) {
            setUrl(`https://arviewer-dev.istaging.com/?uid=${encodeURIComponent(uid)}`);
        }

        if (window.location.hostname.indexOf('areditor.istaging.com') > -1) {
            setUrl(`https://arviewer.istaging.com/?uid=${encodeURIComponent(uid)}`);
        }
    }, []);

    useEffect(() => {
        loginInfoNum(Cookies.get('token')).then((resProjectInfo) => {
            dispatch({ type: USER_PROJECT_NUM, payload: resProjectInfo });
            setProjectCount(resProjectInfo.projectCount);
            setProjectPublicCount(resProjectInfo.projectPublicCount);
        });
        // Cookies.set('isPublish', true);
    }, []);

    const closePublish = () => {
        props.onCallParent(false);
    };

    const [copyStatus, setCopyStatus] = useState(false);

    const copyUrlEvent = (e) => {
        e.preventDefault();
        navigator.clipboard.writeText(publishUrl).then(() => {
            setCopyStatus(true);
            setTimeout(() => setCopyStatus(false), 2000);
        });
    };

    const goPlatformEvent = (e) => {
        e.preventDefault();
        if (window.location.hostname.indexOf('localhost') > -1) {
            window.open(`http://${window.location.hostname}:3022?lang=${Cookies.get('lang')}`, '_self');
        }

        if (window.location.hostname.indexOf('-dev') > -1) {
            window.open(`https://armaker-dev.istaging.com/?lang=${Cookies.get('lang')}`, '_self');
        }

        if (window.location.hostname.indexOf('areditor.istaging.com') > -1) {
            window.open(`https://armaker.istaging.com/?lang=${Cookies.get('lang')}`, '_self');
        }
    };

    return (
        <StyledSuccessProject>
            {
                copyStatus &&
                <div className='copySuccess'>
                    <AiFillCheckCircle />
                    {t('successfullyCopied')}
                </div>
            }
            <div className="whiteBg">
                <a href="#" className='closeIcon' onClick={closePublish}>
                    <AiOutlineClose />
                </a>
                <div className="title">
                    <div className="icon">
                        <AiFillCheckCircle />
                    </div>
                    <div>{props.updateStatus ? t('isSuccessfullyUpdated') : t('isSuccessfullyPublished')}</div>
                </div>
                <div className="copyBar">
                    <input type="text" readOnly value={publishUrl} />
                    <a href="#" onClick={copyUrlEvent} className='copy'>
                        <MdOutlineContentCopy />
                    </a>
                </div>
                <ul className='listUl'>
                    <li>
                        <div>
                            <div className='icon'>
                                <img src={file} />
                            </div>
                            {t('totalCreatedProject')}
                        </div>
                        <div>
                            {
                                projectCount
                            }
                            /
                            {
                                store.permits?.projectLimit
                            }
                        </div>
                    </li>
                    <li>
                        <div>
                            <div className='icon'>
                                <img src={could} />
                            </div>
                            {t('totalPublicProject')}
                        </div>
                        <div>
                            {
                                projectPublicCount
                            }
                            /
                            {
                                store.permits?.publicProject
                            }
                        </div>
                    </li>
                    <li style={{ display: 'none' }}>
                        <div>
                            <div className='icon'>
                                <AiFillEye />
                            </div>
                            {t('totalProjectViews')}
                        </div>
                        <div>
                            {
                                thisMonthCount
                            }
                            /
                            {
                                store.permits?.visitPerMonth
                            }
                        </div>
                    </li>
                    <li className='lastLi'>
                        <div>{t('expirydate')}：
                            {dayjs(store.permits?.createdAt).format('YYYY/MM/DD')}
                            -
                            {dayjs(store.permits?.expiredAt).format('YYYY/MM/DD')}
                        </div>
                    </li>
                    <li>
                        <a href='#' onClick={goPlatformEvent} target='_blank'>{t('goPlatform')}</a>
                        <a href={publishUrl} target='_blank'>{t('checkviewer')}</a>
                    </li>
                </ul>
            </div>
        </StyledSuccessProject>
    );
};

export default SuccessProject;