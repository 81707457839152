import { SET_PERMITS } from 'redux/types/permits.types';

const defaultPermits = {
    createdAt: 0,
    expiredAt: 0,
    projectLimit: 0,
    publicProject: 0,
    startAt: 0,
    visitPerMonth: 0,
};

const permitsReducer = (state = defaultPermits, action) => {
    switch (action.type) {
        case SET_PERMITS:
            return {
                ...state, ...action.payload,
            };
        default: return state;
    }
};

export default permitsReducer;