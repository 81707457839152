import { combineReducers } from 'redux';
import userInfoReducer from './reducers/userInfo.reducer';
import projectInfoReducer from './reducers/projectInfo.reducer';
import permitsReducer from './reducers/permits.reducer';
import shadowCardReducer from './reducers/shadowCard.reducer';
import hdrSkyReducer from './reducers/hdrSky.reducer';
import tagPanelReducer from './reducers/tag.reducer';
import modelReducer from './reducers/model.reducer';
import objectLightReducer from './reducers/objectLight.reducer';
import switchReducer from './reducers/switch.reducer';
import viewModeReducer from './reducers/viewMode.reducer';
import iconReducer from './reducers/icon.reducer';
import permissionReducer from './reducers/permission.reducer';
import userProjectNumReducer from './reducers/userProjectNum.reducer';
import editPanelReducer from './reducers/editPanel.reducer';
import hdrSkySwitchReducer from './reducers/hdrSkySwitch.reducer';
import lastHdrSkyReducer from './reducers/lastHdrSky.reducer';
import secretReducer from './reducers/secret.reducer';
import isSaveReducer from './reducers/isSave.reducer';
import animationReducer from './reducers/animation.reducer';


const rootReducer = combineReducers({
    userInfo: userInfoReducer,
    projectInfo: projectInfoReducer,
    permits: permitsReducer,
    shadowCard: shadowCardReducer,
    hdrSky: hdrSkyReducer,
    tagPanel: tagPanelReducer,
    model: modelReducer,
    objectLights: objectLightReducer,
    switch: switchReducer,
    viewMode: viewModeReducer,
    icon: iconReducer,
    userProjectNum: userProjectNumReducer,
    permission: permissionReducer,
    editPanel: editPanelReducer,
    hdrSkySwitch: hdrSkySwitchReducer,
    lastHdrSky: lastHdrSkyReducer,
    secret: secretReducer,
    isSave: isSaveReducer,
    animation: animationReducer,
});


export default rootReducer;